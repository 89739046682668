import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HttpBackend} from '@angular/common/http';
import {Configuration} from '../entities/Configuration';
import {ConfigUserService} from './configUser.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  httpSenzaInterceptor: HttpClient;

  constructor(private http: HttpClient,
              private handler: HttpBackend,
              private configUserService: ConfigUserService) {
    this.httpSenzaInterceptor = new HttpClient(handler);
  }

  getJSONConfigurationPromisse() {
    return new Promise<void>((resolve, reject) => {
      this.httpSenzaInterceptor.get('./assets/json/configuration.json')
        .subscribe(res => {
            this.configUserService.configuration = new Configuration(res);
            resolve();
          }
        );
    });
  }

}
