export class Configuration {
  restLink: string;  // link
  restLinkLogin: string;  // link
  restLinkJasper: string;  // link
  credencialJasper: string;

  jasperReportName: string;
  parReport: string[];

  jasperRapportinoName: string;
  parRapportino: string[];

  constructor(data) {
    this.restLink = data.apiRestLink;
    this.restLinkLogin = data.apiRestLogin;
    this.restLinkJasper = data.apiRestJasper;
    this.credencialJasper = data.credencialJasper;
    this.jasperReportName = data.jasperReportName;
    this.parReport = data.parReport;
    this.jasperRapportinoName = data.jasperRapportinoName;
    this.parRapportino = data.parRapportino;
  }

  get apiRestLink() {
    return this.restLink;
  }

  get apiRestLinkLogin() {
    return this.restLink;
  }

  get apiRestLinkJasper() {
    return this.restLinkJasper;
  }
}

