import {Injectable} from '@angular/core';
import {HttpInterceptor} from '@angular/common/http';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {HttpEvent} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ConfigUserService} from '../configUser.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class JWTInterceptorService implements HttpInterceptor {

  constructor( private configUserService: ConfigUserService,
               private router: Router ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const refreshToken = sessionStorage.getItem('refreshToken');
    const token = sessionStorage.getItem('token');
    if (token) {
      if (request.url === this.configUserService.configuration.apiRestLink + 'token' && refreshToken) {
        const newRequest = request.clone({setHeaders: {Authorization: `Bearer ${refreshToken}`}});

        return next.handle(newRequest)
          .pipe(
            catchError(error => {  this.goto();  return throwError(error); })
          );
      } else {
        const newRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json'
          }
        });
        return next.handle(newRequest)
          .pipe(
            catchError(error => { this.goto();  return throwError(error); })
          );
      }

    } else {
      return next.handle(request)
        .pipe(
          catchError(error => {  this.goto();  return throwError(error); })
        );
    }
  }

  private goto() {
/*    const param = {};
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login', param]);*/
  }
}
