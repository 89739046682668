import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {HttpRequest} from '@angular/common/http';
import {HttpHandler} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {HttpEvent} from '@angular/common/http';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpClient} from '@angular/common/http';
import {mergeMap, catchError} from 'rxjs/internal/operators';
import {HttpInterceptor} from '@angular/common/http';
import {ConfigUserService} from '../configUser.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshJWTInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector,
              private nav: Router,
              private configUserService: ConfigUserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const error = (typeof errorResponse.error !== 'object') ? JSON.parse(errorResponse.error) : errorResponse;

        if (errorResponse.status === 401 && error.error.message === 'Token has expired') {

          const http = this.injector.get(HttpClient);

          return http.post<any>(this.configUserService.configuration.apiRestLink + 'token', {})
            .pipe(
              mergeMap(data => {
                sessionStorage.setItem('token', data.token);
                const cloneRequest = request.clone({setHeaders: {Authorization: `Bearer ${data.token}`}});
                return next.handle(cloneRequest);
              })
            );
        } else if (errorResponse.status === 401 && error.error.message === 'RefreshToken has expired') {
          sessionStorage.clear();
          this.nav.navigate(['/login']);
        } else if (errorResponse.status === 401 && error.error.message === 'UNF Invalid username or password') {
          sessionStorage.clear();
          this.nav.navigate(['/login']);
        }
        return throwError(error);
      })
    );

  }
}
