import {UtenteRapporto} from './UtenteRapporto';

export class Utente {

  id: number;
  codice: string;
  username: string;
  _descrizione;
  admin: boolean;
  nome: string;
  cognome: string;

  constructor(data) {
    this.username = data.bT07_USERNAME;
    this.codice = data.bT07_CODDIP_VBT03 ?  data.bT07_CODDIP_VBT03.replace(/\s/g, '') : null;
  }

  get descrizione() {
    return this._descrizione ? this._descrizione : this.username;
  }

  get viewValue() {
    return `${this.codice} - ${this.nome}`;
  }

  castUtenteRapporto(): UtenteRapporto {
    return new UtenteRapporto(
        {
          vbT03_CODDIP: this.codice,
          vbT03_NOMEDIP: `${this.nome} ${this.cognome}`,
          vbT03_COSTO1: [],
        }
        );
  }
}


/*
"vbT07_ID": 0,
  "vbT07_USERNAME": "string",
  "vbT07_DESCR": "string",
  "vbT07_FLGISADMIN": 0,
  "vbT07_FLGGRP": 0,
  "vbT07_FLGDITTA": 0,
  "vbT07_WINUSER": "string",
  "vbT07_DEFDITTA_CG18": 0,
  "vbT07_FLGATTIVO": 0,
  "vbT07_INDEMAIL": "string",
  "vbT07_COGNOME": "string",
  "vbT07_NOME": "string",
  */
