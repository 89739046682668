import {Utente} from './Utente';

export class UserConfig {

  private _utente: Utente;
  private _gruppi: string[];

  constructor() {}

  get utente(): Utente {
    return this._utente ? this._utente : new Utente({});
  }

  set utente(value: Utente) {
    this._utente = value;
  }

  get gruppi(): string[] {
    return this._gruppi;
  }

  set gruppi(value: string[]) {
    this._gruppi = value;
  }

  get userAdmin(): boolean {
    return  this.codUser == 'ADMIN';
  }

  get isAdmin(): boolean {
    return this.gruppi.includes('ADMINTIME');
  }

  isAdminOrUser(codUtente: string): boolean {
    return this.gruppi.includes('ADMINTIME') || this.codUser == codUtente;
  }

  get isAgente(): boolean {
    return this.gruppi.includes('AGENTE');
  }

  get isAgenteP(): boolean {
    return this.gruppi.includes('AGENTEP');
  }


  get isCliente(): boolean {
    return this.gruppi.includes('CLIENTE');
  }


  get isTest(): boolean {
    return this.gruppi.includes('TEST');
  }

  get isSysInt(): boolean {
    return this.gruppi.includes('SYSINT');
  }


  get isSvil(): boolean {
    return this.gruppi.includes('SVIL');
  }




  get idUser(): number {
    return this.utente ? this.utente.id : null;
  }

  get codUser(): string {
    return this.utente ? this.utente.username : null;
  }

  get codDip(): string {
    return this.utente ? this.utente.codice : null;
  }

  get username(): string {
    return this.utente ? this.utente.username : null;
  }

}
