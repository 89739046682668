import {EventEmitter, Injectable} from '@angular/core';
import {UserConfig} from '../entities/UserConfig';
import {Configuration} from '../entities/Configuration';

@Injectable({
  providedIn: 'root',
})

export class ConfigUserService {

  private _config: UserConfig = new UserConfig();
  private _configuration: Configuration;

  cambioContent = new EventEmitter();


  constructor( ) {}

  get configuration(): Configuration {
    return this._configuration;
  }

  set configuration(value) {
    this._configuration = value;
  }

  get config() {
    return this._config;
  }


  static myconfig() {
    return 1;
  }


  set config(value) {
    this._config = value;
  }
}
