export class UtenteRapporto {

    codice: string;
    nome: string;
    rapporti: [];
    vbT03_COSTO1: number;

    constructor(data) {
        this.codice = data.vbT03_CODDIP ? data.vbT03_CODDIP.replace(/\s/g, '') : null;
        this.nome = data.vbT03_NOMEDIP;
        this.rapporti = data.bT50_RAPPORTI;
        this.vbT03_COSTO1 = data.vbT03_COSTO1;
    }

    get viewValue() {
        return `${this.codice} - ${this.nome}`;
    }
}
